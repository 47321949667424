import React, { Component } from 'react'

export class HeaderTop extends Component {
  closeMenu(e) {
    e.target.closest(".dropdown").classList.remove("show");
    e.target.closest(".dropdown .dropdown-menu").classList.remove("show");
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
    document.getElementById('sidebar').classList.toggle("sidebar-margin");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.querySelector("body").classList.remove("az-header-menu-show");
      
    }
  }

  render() {
    return (
      <div className="d-flex az-header-top justify-content-between mobile-fixed" style={{position: 'sticky', top: '0', width: '100%', backgroundColor: 'white', zIndex: '1000'}}>
        <div className="az-header-left">
          <a href="#/" id="azMenuShow" onClick={event => this.toggleHeaderMenu(event)} className="az-header-menu-icon"><span></span></a>
        </div>
      </div>
    );
  }

}

export default HeaderTop
