import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import PropTypes from 'prop-types';
import AuthToken from '../context/AuthToken';

export class Signin extends Component {
  static contextType = AuthToken;

  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      email: '',
      password: '',
      signInSuccess: false,
      error: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    console.log(this.context)
  }

  handleSubmit = event => {
    const api = this.context.api;
    event.preventDefault();

    const signInInfo = {
      username: this.state.email,
      password: this.state.password
    };

    axios.post(api + `/api/auth/signin`, signInInfo)
      .then(res => {
        console.log(res.data);
        if (res.data.accessToken !== null) {
          this.context.setToken(res.data.accessToken);
          this.context.setSecurityGroups(res.data.securityGroups);
          this.context.setUserName(res.data.name)
          sessionStorage.setItem('token', res.data.accessToken);
        }
        this.setState({ signInSuccess: true });
      })
      .catch(err => {
        console.log(err)
        switch (err.response.status) {
          case 404:
            this.setState({ 'error': 404 })
            break;
          case 401:
            this.setState({ 'error': 401 })
            break;
          default:
            break;
        }
      })
  }

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  }

  render() {
    this.propTypes = {
      setToken: PropTypes.func.isRequired
    }

    return (
      <div>
        {this.state.signInSuccess && document.getElementById('sidebar').classList.add("sidebar-margin")}
        {this.state.signInSuccess && <Redirect to="/dashboard" />}
        <div className="az-signin-wrapper">
          <div className="az-card-signin">
            <h1 className="az-logo justify-content-center tx-40">Bear Creek Villa</h1>
            <div className="az-signin-header">
              <h2>Welcome back!</h2>
              <h4>Please sign in to continue</h4>

              <form name="contactform" className="contactform" onSubmit={this.handleSubmit}>
                {this.state.error === 404 ? <h6 style={{ textAlign: 'center', color: 'firebrick' }}>Incorrect email or password</h6> : ''}
                {this.state.error === 401 ? <h6 style={{ textAlign: 'center', color: 'firebrick' }}>Incorrect password</h6> : ''}
                <div className="form-group" >
                  <label>Email</label>
                  <input refs="email" type="text" className="form-control" placeholder="Enter your email" onChange={this.handleEmailChange} required />
                </div>{/* form-group */}
                <div className="form-group">
                  <label>Password</label>
                  <input refs="password" type="password" className="form-control" placeholder="Enter your password" onChange={this.handlePasswordChange} required />
                </div>{/* form-group */}
                <button type="submit" className="btn btn-az-primary btn-block">Sign In</button>
                {/*<Link to="/" className="btn btn-az-primary btn-block">Sign In</Link> */}
              </form>
            </div>{/* az-signin-header */}
            {/* <div className="az-signin-footer">
              <Link to="/public/register" className="btn btn-az-primary btn-block mg-t-20">Register</Link>
            </div> */}
          </div>{/* az-card-signin */}
        </div>{/* az-signin-wrapper */}
      </div>
    )
  }
}

export default Signin
