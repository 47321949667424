import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import AuthToken from './context/AuthToken'
import icon from '../assets/images/bearcreekvilla/logo_foot.png';

export class Sidebar extends Component {
    static contextType = AuthToken;
    constructor(props) {
        super(props);

        this.state = {
            signOutSuccess: false
        }

        this.handleLogOut = this.handleLogOut.bind(this);
    }

    handleLogOut() {
        sessionStorage.clear();
        this.setState({ signOutSuccess: true })
    }

    render() {
        const securityGroups = this.context.securityGroups;
        let userName = 'Bear Creek Villa';
        let position = this.props.userName;
        if (securityGroups === null) {
            return ('');
        }
        return (
            <div className="az-header" >
                {this.state.signOutSuccess ? <Redirect to="/public/sign-in" /> : ''}
                <div>
                    <div className="az-header-menu-header">
                        <Link to="/" className="az-logo">
                            <span></span> Bear Creek Villa
                        </Link>
                        <a
                            href="#/"
                            onClick={event => this.toggleHeaderMenu(event)}
                            className="close"
                        >
                            &times;
                        </a>
                    </div>
                    <div className="az-header-menu">
                        <div className="az-sidebar-loggedin">
                            <div className="az-img-user online">
                                <img src={icon} alt=""></img>
                            </div>
                            <div className="media-body">
                                <h6>{userName}</h6>
                                <span>{position}</span>
                                <p onClick={this.handleLogOut}>Log Out</p>
                            </div>
                        </div>
                        <ul className="nav">
                            <li
                                className={
                                    this.isPathActive("/dashboard")
                                        ? "nav-item active"
                                        : "nav-item"
                                }
                            >
                                <Link to="/dashboard" className="nav-link">
                                    <i className="typcn typcn-chart-area-outline"></i> Dashboard
                                </Link>
                            </li>
                            {securityGroups.includes('Admin') || securityGroups.includes('Meal Attendance') || securityGroups.includes('View Reports') || securityGroups.includes('Manage Tasks') || securityGroups.includes('View Facility Map') || securityGroups.includes('View Agenda')?
                                <li className="nav-item">
                                    <Dropdown
                                        className={
                                            this.isPathActive("/overviews")
                                                ? "nav-item active"
                                                : "nav-item"
                                        }
                                    >
                                        <Dropdown.Toggle as={"a"} className="nav-link with-sub">
                                            <i className="typcn typcn-chart-bar-outline"></i> Overviews
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="az-menu-sub">
                                            {securityGroups.includes('Admin') || securityGroups.includes('View Reports') ?
                                                <Link
                                                    to="/overviews/reports"
                                                    className={
                                                        this.isPathActive("/overviews/reports")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Reports
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') || securityGroups.includes('Meal Attendance') ?
                                                <Link
                                                    to="/overviews/meal-attendance"
                                                    className={
                                                        this.isPathActive("/overviews/meal-attendance")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Meal Attendance
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') || securityGroups.includes('Manage Tasks') ?
                                                <Link
                                                    to="/overviews/task-management"
                                                    className={
                                                        this.isPathActive("/overviews/task-management")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Task Management
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') || securityGroups.includes('View Facility Map') ?
                                                <Link
                                                    to="/overviews/facility-map"
                                                    className={
                                                        this.isPathActive("/overviews/facility-map")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Facility Map
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') || securityGroups.includes('View Agenda') ?
                                                <Link
                                                    to="/overviews/agenda"
                                                    className={
                                                        this.isPathActive("/overviews/agenda")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Agenda
                                                </Link>
                                                : ''}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                : ''}

                            {securityGroups.includes('Admin') || securityGroups.includes('View Tenants') || securityGroups.includes('Create Tenants') || securityGroups.includes('Edit Tenants') || securityGroups.includes('Manage Tenant Document Types')
                                || securityGroups.includes('View Staffs') || securityGroups.includes('Create Staffs') || securityGroups.includes('Edit Staffs') || securityGroups.includes('Manage Staff Document Types') ?
                                <li className="nav-item">
                                    <Dropdown
                                        className={
                                            this.isPathActive("/administration")
                                                ? "nav-item active"
                                                : "nav-item"
                                        }
                                    >
                                        <Dropdown.Toggle as={"a"} className="nav-link with-sub">
                                            <i className="typcn typcn-tabs-outline"></i> Administration
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="az-menu-sub">
                                            {securityGroups.includes('Admin') || securityGroups.includes('View Staffs') || securityGroups.includes('Create Staffs') || securityGroups.includes('Edit Staffs') || securityGroups.includes('Manage Staff Document Types') ?
                                                <Link
                                                    to="/administration/staff-management"
                                                    className={
                                                        this.isPathActive("/administration/staff-management")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Staff Management
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') || securityGroups.includes('View Tenants') || securityGroups.includes('Create Tenants') || securityGroups.includes('Edit Tenants') || securityGroups.includes('Manage Tenant Document Types') ?
                                                <Link
                                                    to="/administration/tenant-management"
                                                    className={
                                                        this.isPathActive("/administration/tenant-management")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Tenant Management
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') || securityGroups.includes('View Suites') || securityGroups.includes('Create Suites') || securityGroups.includes('Edit Suites') ?
                                                <Link
                                                    to="/administration/suite-management"
                                                    className={
                                                        this.isPathActive("/administration/suite-management")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Suite Management
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') ?
                                                <Link
                                                    to="/administration/user-management"
                                                    className={
                                                        this.isPathActive("/administration/user-management")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    User Management
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') || securityGroups.includes('View Suites') || securityGroups.includes('Create Suites') || securityGroups.includes('Edit Suites') ?
                                                <Link
                                                    to="/administration/appliance-management"
                                                    className={
                                                        this.isPathActive("/administration/appliance-management")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Appliance Management
                                                </Link>
                                                : ''}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                : ''}

                            {securityGroups.includes('Admin') ?
                                <li className="nav-item">
                                    <Dropdown
                                        className={
                                            this.isPathActive("/management")
                                                ? "nav-item active"
                                                : "nav-item"
                                        }
                                    >
                                        <Dropdown.Toggle as={"a"} className="nav-link with-sub">
                                            <i className="typcn typcn-folder"></i> Management
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="az-menu-sub">
                                            {securityGroups.includes('Admin') ?
                                                <Link
                                                    to="/management/service-types"
                                                    className={
                                                        this.isPathActive("/management/service-types")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Service Types
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') ?
                                                <Link
                                                    to="/management/services"
                                                    className={
                                                        this.isPathActive("/management/services")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Services
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') ?
                                                <Link
                                                    to="/management/outing-types"
                                                    className={
                                                        this.isPathActive("/management/outing-types")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Outing Types
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') ?
                                                <Link
                                                    to="/management/outings"
                                                    className={
                                                        this.isPathActive("/management/outings")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Outings
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') ?
                                                <Link
                                                    to="/management/activity-types"
                                                    className={
                                                        this.isPathActive("/management/activity-types")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Activity Types
                                                </Link>
                                                : ''}
                                            {securityGroups.includes('Admin') ?
                                                <Link
                                                    to="/management/activities"
                                                    className={
                                                        this.isPathActive("/management/activities")
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Activities
                                                </Link>
                                                : ''}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                : ''}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

export default withRouter(Sidebar);
