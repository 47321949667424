import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthToken from './context/AuthToken'

import Signin from './public/Signin'
import Loading from './public/Loading'

const Register = lazy(() => import('./public/Register'))

const Dashboard = lazy(() => import('./dashboard/Dashboard'))

const TenantManagement = lazy(() => import('./administration/TenantManagement'))
const StaffManagement = lazy(() => import('./administration/StaffManagement'))
const SuiteManagement = lazy(() => import('./administration/SuiteManagement'))
const UserManagement = lazy(() => import('./administration/UserManagement'))
const ApplianceManagement = lazy(() => import('./administration/ApplianceManagement'));
//const RoleManagement = lazy(() => import('./administration/RoleManagement'))

const ServiceTypes = lazy(() => import('./management/ServiceTypes'))
const Services = lazy(() => import('./management/Services'))
const OutingTypes = lazy(() => import('./management/OutingTypes'))
const Outings = lazy(() => import('./management/Outings'))
const ActivityTypes = lazy(() => import('./management/ActivityTypes'))
const Activities = lazy(() => import('./management/Activities'))

const Agenda = lazy(() => import('./overviews/Agenda'))
const MealAttendance = lazy(() => import('./overviews/MealAttendance'))
const MealAttendanceViewer = lazy(() => import('./overviews/MealAttendanceViewer'))
const ManageTasks = lazy(() => import('./overviews/ManageTasks'))
const Reports = lazy(() => import('./overviews/Reports'))
const FacilityMap = lazy(() => import('./overviews/FacilityMap'))


export class ContentRoutes extends Component {
    static contextType = AuthToken;
    render() {
        const securityGroups = this.context.securityGroups;
        if (securityGroups === null) {
            return (
                <Suspense fallback=''>
                    <Switch>
                        <Route exact path="/public/sign-in" component={Signin} />
                        <Route exact path="/public/register" component={Register} />

                        {sessionStorage.getItem('token') === null ? <Redirect to="/public/sign-in"></Redirect> : ''}

                        <Route exact path="/">
                            <Redirect to="/public/sign-in"></Redirect>
                        </Route>
                    </Switch>
                </Suspense>
            )
        }
        else {
            return (
                <Suspense fallback=''>
                    <Switch>
                        <Route exact path="/public/sign-in" component={Signin} />
                        <Route exact path="/public/register" component={Register} />

                        {sessionStorage.getItem('token') === null ? <Redirect to="/public/sign-in"></Redirect> : ''}

                        <Route exact path="/">
                            <Redirect to="/dashboard"></Redirect>
                        </Route>
                        {securityGroups === null ? <Route exact path="/dashboard" component={Loading} /> : <Route exact path="/dashboard" component={Dashboard} />}

                        {securityGroups.includes('Admin') || securityGroups.includes('View Tenants') || securityGroups.includes('Create Tenants') || securityGroups.includes('Edit Tenants') || securityGroups.includes('Manage Tenant Document Types') ? <Route exact path="/administration/tenant-management" component={TenantManagement} /> : <Route exact path="/administration/tenant-management"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') || securityGroups.includes('View Staffs') || securityGroups.includes('Create Staffs') || securityGroups.includes('Edit Staffs') || securityGroups.includes('Manage Staff Document Types') ? <Route exact path="/administration/staff-management" component={StaffManagement} /> : <Route exact path="/administration/staff-management"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') || securityGroups.includes('View Suites') || securityGroups.includes('Create Suites') || securityGroups.includes('Edit Suites') ? <Route exact path="/administration/suite-management" component={SuiteManagement} /> : <Route exact path="/administration/suite-management"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') ? <Route exact path="/administration/user-management" component={UserManagement} /> : <Route exact path="/administration/user-management"><Redirect to="/dashboard"></Redirect></Route>}
                        {/* {securityGroups.includes('Admin') ? <Route exact path="/administration/RoleManagement" component={RoleManagement} /> : <Route exact path="/administration/RoleManagement"><Redirect to="/dashboard"></Redirect></Route>} */}
                        {securityGroups.includes('Admin') || securityGroups.includes('View Suites') || securityGroups.includes('Create Suites') || securityGroups.includes('Edit Suites') ? <Route exact path="/administration/appliance-management" component={ApplianceManagement} /> : <Route exact path="/administration/appliance-management"><Redirect to="/dashboard"></Redirect></Route>}

                        {securityGroups.includes('Admin') ? <Route exact path="/management/service-types" component={ServiceTypes} /> : <Route exact path="/management/service-types"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') ? <Route exact path="/management/services" component={Services} /> : <Route exact path="/management/services"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') ? <Route exact path="/management/outing-types" component={OutingTypes} /> : <Route exact path="/management/outing-types"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') ? <Route exact path="/management/outings" component={Outings} /> : <Route exact path="/management/outings"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') ? <Route exact path="/management/activity-types" component={ActivityTypes} /> : <Route exact path="/management/activity-types"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') ? <Route exact path="/management/activities" component={Activities} /> : <Route exact path="/management/activities"><Redirect to="/dashboard"></Redirect></Route>}

                        {securityGroups.includes('Admin') || securityGroups.includes('View Agenda') ? <Route exact path="/overviews/agenda" component={Agenda} /> : <Route exact path="/overviews/agenda"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') || securityGroups.includes('Meal Attendance') ? <Route exact path="/overviews/meal-attendance" component={MealAttendance} /> : <Route exact path="/overviews/meal-attendance"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') || securityGroups.includes('Meal Attendance') ? <Route exact path="/overviews/meal-attendance/viewer" component={MealAttendanceViewer} /> : <Route exact path="/overviews/meal-attendance/viewer"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') || securityGroups.includes('Manage Tasks') ? <Route exact path="/overviews/task-management" component={ManageTasks} /> : <Route exact path="/overviews/task-management"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') || securityGroups.includes('View Reports') ? <Route path="/overviews/reports" component={Reports} /> : <Route exact path="/overviews/reports"><Redirect to="/dashboard"></Redirect></Route>}
                        {securityGroups.includes('Admin') || securityGroups.includes('View Facility Map') ? <Route path="/overviews/facility-map" component={FacilityMap} /> : <Route exact path="/overviews/facility"><Redirect to="/dashboard"></Redirect></Route>}

                        {<Route component={Loading} />}
                    </Switch>
                </Suspense>
            )
        }

    }
}

export default ContentRoutes
