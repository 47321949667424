import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import HeaderTopBar from './shared/HeaderTop';
import Footer from './shared/Footer';
import ContentRoutes from './ContentRoutes';
import Sidebar from './Sidebar';
import AuthToken from './context/AuthToken';
import axios from 'axios';
import { io } from 'socket.io-client'

class App extends Component {
  constructor(props) {
    super(props);

    this.setToken = this.setToken.bind(this);
    console.log(process.env)

    let api = 'http://127.0.0.1:3001';

    if (process.env.REACT_APP_MODE === 'staging') {
      api = 'https://api-staging.aident.space';
    }
    else if (process.env.REACT_APP_MODE === 'production') {
      api = 'https://api.aident.space';
    }

    const socket = io(api)

    socket.on("connect", () => {
      console.log(socket.connected); // true
    });

    socket.on("connect_error", (error) => {
      console.log(`connect_error due to ${error.message}`);
      socket.disconnect();
    });

    this.state = {
      location: '',
      token: null,
      securityGroups: null,
      setToken: this.setToken,
      setSecurityGroups: this.setSecurityGroups,
      setUserName: this.setUserName,
      socket: socket,
      api: api,
      userName: '',
    }
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
    document.getElementById('sidebar').classList.toggle("sidebar-margin");
  }

  componentDidMount() {
    this.onRouteChanged();
    const token = sessionStorage.getItem('token')
    if (token != null) {
      this.setState({ token: token })
      axios.get(this.state.api + '/api/auth/permission', { headers: { 'x-access-token': token } })
        .then((res) => {
          if (res.data.securityGroupNames != null) {
            this.setSecurityGroups(res.data.securityGroupNames);
            this.setUserName(res.data.name)
            console.log(res.data)
          }
        })
    }
  }

  setToken = (userToken) => {
    this.setState({ token: userToken });
  }
  setSecurityGroups = (securityGroups) => {
    this.setState({ securityGroups: securityGroups });
  }
  setUserName = (userName) => {
    this.setState({ userName: userName })
  }

  render() {
    let headerComponent = !this.state.isFullPageLayout ? <Sidebar userName={this.state.userName} /> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
    let HeaderTopComponent = !this.state.isFullPageLayout ? <HeaderTopBar /> : '';

    return (
      <AuthToken.Provider value={this.state}>
        <div className="d-flex">
          <div className="az-sidebar" style={{ position: 'fixed', zIndex: '2000' }}>
            {headerComponent}
          </div>
          <div className="w-100">
            <div className="az-content-wrapper sidebar-margin" id='sidebar'>
              {HeaderTopComponent}
              <div>
                <ContentRoutes />
              </div>
            </div>
            {footerComponent}
            <div className="az-navbar-backdrop" onClick={event => this.toggleHeaderMenu(event)}></div>
          </div>
        </div>
      </AuthToken.Provider>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    this.setState({ location: this.props.location.pathname });
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/public/sign-in', '/public/register', '/general-pages/page-404', '/public/loading'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.az-sidebar').classList.add('d-none');
        document.getElementById('sidebar').classList.remove("sidebar-margin");
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.az-sidebar').classList.remove('d-none');
      }
    }
  }

}


export default withRouter(App);
