import React, { Component } from 'react'


export default class Loading extends Component {
    render() {
        return (
            <div style={{
                justifyContent: 'center',
                height: '100vh',
                display: 'table-cell',
                verticalAlign: 'middle',
            }}>
                <div style={{
                    
                    left: '45%',
                    marginRight: '-50%',
                    position: 'absolute',

                }}>
                    <div className="spinner-border" role="status" style={{ width: '10vh', height: '10vh' }}>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div >
            </div>


        )

    }
}